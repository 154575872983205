.App {
    display: flex;
    flex-direction: column;
    align-items: center;
}
body {
    height: 100vh;
    margin: 0;
    display: grid;
    place-items: center;
}
::placeholder {
            color: #7a777d !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.box {
    width: 300px;
}
h1 {
    font-size: 20px;
    margin: 0 0 1rem 0;
}
.w-6 {
    width: 1.5em;
}
.h-6 {
    height: 1.5em;
}
.flex {
    display: flex;
}
.cursor-pointer {
    cursor: pointer;
}
