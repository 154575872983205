.invoice * {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
body {
    display: block;
}

@font-face {
    font-display: auto;
    font-family: "product_sansregular";
    src: url("../fonts/productsans/productsans-regular-webfont.woff2")
            format("woff2"),
        url("../fonts/productsans/productsans-regular-webfont.woff")
            format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: "product_sansbold";
    src: url("../fonts/productsans/productsans-bold-webfont.woff2")
            format("woff2"),
        url("../fonts/productsans/productsans-bold-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
