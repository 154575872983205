@font-face {
    font-family: "baloo_paaji_2semibold";
    src: url("./assets/fonts/BalooPaaji2-SemiBold.woff") format("woff2"),
        url("./assets/fonts/BalooPaaji2-SemiBold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "baloo_paaji_2regular";
    src: url("./assets/fonts/BalooPaaji2-Regular.woff2") format("woff2"),
        url("./assets/fonts/BalooPaaji2-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "baloo_paaji_2medium";
    src: url("./assets/fonts/BalooPaaji2-Medium.woff2") format("woff2"),
        url("./assets/fonts/BalooPaaji2-Medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "baloo_paaji_2bold";
    src: url("./assets/fonts/BalooPaaji2-Bold.woff2") format("woff2"),
        url("./assets/fonts/BalooPaaji2-Bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
