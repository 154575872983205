.invoice * {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    background: transparent;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
body {
    display: block;
}

@font-face {
    font-display: auto;
    font-family: "product_sansregular";
    src: url("../fonts/productsans/productsans-regular-webfont.woff2") format("woff2"),
        url("../fonts/productsans/productsans-regular-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: "product_sansbold";
    src: url("../fonts/productsans/productsans-bold-webfont.woff2") format("woff2"),
        url("../fonts/productsans/productsans-bold-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: gordita_regular;
    src: url(../fonts/gordita/GorditaRegular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: auto;
    font-family: gordita_medium;
    src: url(../fonts/gordita//GorditaMedium.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: gordita_bold;
    src: url(../fonts/gordita/GorditaBold.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold-webfont.woff') format('woff2'),
         url('../fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinslight';
    src: url('../fonts/poppins-light-webfont.woff') format('woff2'),
         url('../fonts/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsmedium';
    src: url('../fonts/poppins-medium-webfont.woff') format('woff2'),
         url('../fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular-webfont.woff') format('woff2'),
         url('../fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinssemibold';
    src: url('../fonts/poppins-semibold-webfont.woff') format('woff2'),
         url('../fonts/poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
/* #1b474e; */